// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-components-job-description-js": () => import("./../src/components/jobDescription.js" /* webpackChunkName: "component---src-components-job-description-js" */),
  "component---src-components-portfolios-portfoliosingle-js": () => import("./../src/components/Portfolios/Portfoliosingle.js" /* webpackChunkName: "component---src-components-portfolios-portfoliosingle-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-careers-js": () => import("./../src/pages/about/careers.js" /* webpackChunkName: "component---src-pages-about-careers-js" */),
  "component---src-pages-about-faq-js": () => import("./../src/pages/about/faq.js" /* webpackChunkName: "component---src-pages-about-faq-js" */),
  "component---src-pages-about-process-js": () => import("./../src/pages/about/process.js" /* webpackChunkName: "component---src-pages-about-process-js" */),
  "component---src-pages-about-team-js": () => import("./../src/pages/about/team.js" /* webpackChunkName: "component---src-pages-about-team-js" */),
  "component---src-pages-contact-js": () => import("./../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-policy-end-user-license-agreement-js": () => import("./../src/pages/policy/end-user-license-agreement.js" /* webpackChunkName: "component---src-pages-policy-end-user-license-agreement-js" */),
  "component---src-pages-policy-online-privacy-policy-js": () => import("./../src/pages/policy/online-privacy-policy.js" /* webpackChunkName: "component---src-pages-policy-online-privacy-policy-js" */),
  "component---src-pages-policy-terms-and-conditions-js": () => import("./../src/pages/policy/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-policy-terms-and-conditions-js" */),
  "component---src-pages-portfolio-js": () => import("./../src/pages/portfolio.js" /* webpackChunkName: "component---src-pages-portfolio-js" */),
  "component---src-pages-services-dev-ops-js": () => import("./../src/pages/services/dev-ops.js" /* webpackChunkName: "component---src-pages-services-dev-ops-js" */),
  "component---src-pages-services-process-automation-js": () => import("./../src/pages/services/process-automation.js" /* webpackChunkName: "component---src-pages-services-process-automation-js" */),
  "component---src-pages-services-research-and-development-js": () => import("./../src/pages/services/research-and-development.js" /* webpackChunkName: "component---src-pages-services-research-and-development-js" */),
  "component---src-pages-services-software-js": () => import("./../src/pages/services/software.js" /* webpackChunkName: "component---src-pages-services-software-js" */),
  "component---src-pages-services-staff-augmentation-js": () => import("./../src/pages/services/staff-augmentation.js" /* webpackChunkName: "component---src-pages-services-staff-augmentation-js" */),
  "component---src-pages-thank-you-js": () => import("./../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */)
}

