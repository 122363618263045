import ReactPixel from 'react-facebook-pixel';

export default () => {
  if (window.location.host === 'www.opna.dev') {
    ReactPixel.init(
      '232613217813544',
      {},
      {
        autoConfig: true,
        debug: false,
      },
    );

    ReactPixel.pageView(); // track page views
  }
}
