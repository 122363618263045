/* eslint-disable no-unused-expressions */
/* eslint-disable no-void */
/* eslint-disable no-sequences */
/* eslint-disable array-bracket-spacing */
/* eslint-disable no-multi-assign */
/* eslint-disable no-undef */
export default () => {
  const t = window.driftt = window.drift = window.driftt || [];
  if (!t.init) {
    if (t.invoked) return void (window.console && console.error && console.error('Drift snippet included twice.'));
    t.invoked = !0, t.methods = [ 'identify', 'config', 'track', 'reset', 'debug', 'show', 'ping', 'page', 'hide', 'off', 'on' ], 
    t.factory = (e) => {
      return () => {
        var n = Array.prototype.slice.call(arguments);
        return n.unshift(e), t.push(n), t;
      };
    }, t.methods.forEach((e) => {
      t[e] = t.factory(e);
    }), t.load = (t) => {
      var e = 3e5, n = Math.ceil(new Date() / e) * e, o = document.createElement('script');
      o.type = 'text/javascript', o.async = !0, o.crossorigin = 'anonymous', o.src = 'https://js.driftt.com/include/' + n + '/' + t + '.js';
      const i = document.getElementsByTagName('script')[0];
      i.parentNode.insertBefore(o, i);
    };
  }
  drift.SNIPPET_VERSION = '0.3.1';
  drift.load('vs7y7r23h7ng');
};
