import React, { useState, useEffect } from 'react';
import { Link } from 'gatsby';
import urls from '../data/urls';

const Popup = () => {
  const [accepted, setAccepted] = useState();
  useEffect(() => {
    setAccepted(localStorage.getItem('accepted'));
  });

  if (accepted) {
    return null;
  }

  return (
    <div className="cookie-popup fixed-bottom">
      <div className="d-flex justify-content-center align-items-center mx-auto flex-wrap">
        <p className="my-0 mx-4 text-white">
          {'Our site uses cookies. By using our site you are agreeing to our '}
          <Link
            to={urls.privacyPolicy}
          >
            Privacy Policy
          </Link>
          .
        </p>
        <button
          className="btn_hover app_btn my-5 text-nowrap"
          onClick={() => {
            localStorage.setItem('accepted', true);
            setAccepted(true);
          }}
          type="button"
        >
          Accept
        </button>
      </div>
    </div>
  );
};

export default Popup;
