import React from 'react';
import { Link, graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import urls from '../../data/urls';

export default () => {
  const imgData = useStaticQuery(graphql`
    query {
      contentfulAsset(title: {eq: "contact"}) {
        fluid {
          aspectRatio
          base64
          sizes
          src
          srcSet
          srcSetWebp
          srcWebp
        }
      }
    }
  `);
  const image = imgData.contentfulAsset.fluid;
  
  return (
    <section className="seo_call_to_action_area sec_pad">
      <br />
      <div className="container mx-auto">
        <div className="seo_call_action_text">
          <div className="col-lg-2 d-none d-lg-block">
            <Img fluid={image} />
          </div>
          <h2>
            Ready to get started?
            <br />
            {' '}
            Let's talk!
          </h2>
          <Link to={urls.contactUs} className="about_btn mx-4">Contact us</Link>
        </div>
      </div>
    </section>
  );
}
