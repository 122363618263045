import React from 'react';
import { Link } from 'gatsby';

const NavLink = ({ title, url, collapse }) => {
  if (collapse) {
    return (
      <li
        className="nav-item"
        data-toggle="collapse"
        data-target="#navbarSupportedContent"
      >
        <Link title={title} className="nav-link ml-3 ml-lg-0 ml-xl-0" to={url}>{title}</Link>
      </li>
    );
  }
  return (
    <li className="nav-item">
      <Link title={title} className="nav-link ml-3 ml-lg-0 ml-xl-0" to={url}>{title}</Link>
    </li>
  );
};

export default NavLink;
