import urls from './urls';

export default {
  about: [
    {
      title: 'Process',
      url: urls.process,
    },
    {
      title: 'FAQ',
      url: urls.faq,
    },
    {
      title: 'Team',
      url: urls.team,
    },
    {
      title: 'Careers',
      url: urls.careers,
    },
  ],
  services: [
    {
      title: 'Software',
      url: urls.software,
    },
    {
      title: 'Dev Ops',
      url: urls.devOps,
    },
    {
      title: 'Staff Augmentation',
      url: urls.staffAugmentation,
    },
    {
      title: 'R & D',
      url: urls.rnd,
    },
    {
      title: 'Process Automation',
      url: urls.automation,
    },
  ],
  policy: [
    {
      title: 'End User License Agreement',
      url: urls.endUserLicenseAgreement,
    },
    {
      title: 'Terms and Conditions',
      url: urls.termsAndConditions,
    },
    {
      title: 'Privacy Policy',
      url: urls.privacyPolicy,
    },
  ],
  socialIcon: [
    {
      url: 'https://www.linkedin.com/company/opna-dev/',
      icon: 'ti-linkedin',
    },
    {
      url: 'https://www.facebook.com/opnadev/',
      icon: 'ti-facebook',
    },
  ],
};
