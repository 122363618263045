export default {
  home: '/',
  about: '/about/',
  team: '/about/team/',
  process: '/about/process/',
  faq: '/about/faq/',
  careers: '/about/careers/',
  software: '/services/software/',
  devOps: '/services/dev-ops/',
  staffAugmentation: '/services/staff-augmentation/',
  rnd: '/services/research-and-development/',
  automation: '/services/process-automation/',
  portfolio: '/portfolio/',
  blog: '/blog/',
  contactUs: '/contact/',
  api: {
    contactUs: 'https://us-central1-opna-static-site.cloudfunctions.net/contactUs',
  },
  services: '/services/',
  endUserLicenseAgreement: '/policy/end-user-license-agreement/',
  termsAndConditions: '/policy/terms-and-conditions/',
  privacyPolicy: '/policy/online-privacy-policy/',
  thankYou: '/thank-you/',
};
