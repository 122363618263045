/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useEffect } from 'react'
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';

import drift from '../util/Drift';
import fbPixel from '../analytics/FacebookPixel';

import '../assets/themify-icon/themify-icons.css';
import '../assets/simple-line-icon/simple-line-icons.css';
import '../assets/elagent/style.css';
import '../assets/animate.css';
import '../assets/main.css';
import '../assets/responsive.css';

import './layout.css';

import SEO from '../components/seo';
import Breadcrumb from '../components/Breadcrumb';
import CustomNavbar from '../components/CustomNavbar';
import PrototypeFooter from '../components/Footer/PrototypeFooter';
import ContactUs from '../components/ContactUs/ContactUs';
import pageData from '../data/pageData';
import CookiePopup from '../components/cookiePopup';

const careers = require('../data/careers.js');

const noContactFooter = [
  'Page not found',
  'Contact Us',
  'End User License Agreement',
  'Terms and Conditions',
  'Privacy Policy',
  'Thanks for reaching out!',
];

const Layout = ({ children, location: { pathname } }) => {
  useEffect(() => {
    require('bootstrap/dist/css/bootstrap.min.css');
    require('jquery/dist/jquery.min.js');
    require('bootstrap/dist/js/bootstrap.min.js');
    require('slick-carousel/slick/slick.css');
    require('slick-carousel/slick/slick-theme.css');

    drift();
    fbPixel();
  });

  const data = useStaticQuery(graphql`
    query {
      allContentfulCaseStudy {
        nodes {
          bannerImage { file { 
            url
          }}
          slug
          title
          description
          feature { fixed(width: 262) {
            aspectRatio
            base64
            height
            src
            srcSet
            srcSetWebp
            srcWebp
            width
          }}
        }
      }
    }
  `);

  data.allContentfulCaseStudy.nodes.forEach((project) => {
    pageData[`/portfolio/${project.slug}/`] = {
      title: project.title,
      description: project.description,
      bannerImage: project.bannerImage ? project.bannerImage.file.url : null,
    }
  });

  Object.keys(careers).forEach((job) => {
    pageData[`/about/careers/${job}/`] = {
      title: careers[job].title,
      description: careers[job].description,
    };
  });

  let page = pageData[pathname];
  if (!page) {
    console.warn(`url mismatch: ${pathname} not in pageData`);
    page = pageData['/404/'];
  }

  return (
    <>
      <div className="body_wrapper">
        <SEO title={page.title} />
        <CustomNavbar
          slogo="sticky_logo"
          mClass="menu_four"
          nClass="w_menu"
          hbtnClass="btn_get_radious menu_custfive"
          cClass="custom_container px-3"
          portfolio={data.allContentfulCaseStudy.nodes}
        />
        {(() => {
          if (!page.noBreadcrumb) {
            return (
              <Breadcrumb
                breadcrumbClass="breadcrumb_area"
                imgName={page.bannerImage || 'breadcrumb/banner_bg.png'}
                Ptitle={page.title}
                Pdescription={page.description}
              />
            );
          }
        })()}
        <main>{children}</main>
        {(() => {
          if (!noContactFooter.includes(page.title)) return <ContactUs />;
        })()}
        <PrototypeFooter rclass="payment_footer_area_two" />
        <CookiePopup />
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout;
