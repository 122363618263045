module.exports = {
  'frontend-engineer': {
    title: 'Front End Engineer',
    description: 'Opna is looking for product-minded UI Software Engineers. You should have a track record of delivering highly usable websites, be passionate about emerging technologies, and care deeply about the user experience.',
    responsibilities: [
      'Work as part of a collaborative, agile team to deliver industry-leading engagement capabilities on the web.',
      'Integrate with other services to help our apps seamlessly provide value to our clients.',
      'Develop usable, engaging UIs for Desktop and Mobile browsers.',
    ],
    requiredSkills: [
      '3+ years experience with web applications living in production with active users.',
      'Unit and automated testing experience (e.g. jest, karma, mocha, sinon).',
      'Ability to empathize with end-users; build with scale and ease of adoption in mind.',
      'Experience building in an agile setting with code reviews and quality as a priority.',
      'Strong design skills for separation and modularity of code.',
      'Worked with a React or another single page application framework: Angular, Vue.',
    ],
    bonusSkills: [
      'Experience with app development platforms such as Firebase, App Engine and Netlify.',
      'Worked with Gatsby.',
      'Experience with web accessibility',
      '3rd party API usage and integration experience.',
      'Experience writing server code in Node.js or Django',
      'Design experience: Adobe Illustrator, Sketch.',
      'UX testing.',
    ],
  },
  'dev-ops': {
    title: 'Sr. DevOps Engineer',
    description: 'We’re looking for a DevOps engineer with experience designing and supporting enterprise scale architectures. This person should understand portability and replication of modern cloud tooling. They should also enjoy working on high-impact projects from a leadership position.',
    responsibilities: [
      'Work as part of a collaborative, agile team with our clients to deliver industry-leading scalable architectures.',
      'Troubleshoot and resolve performance issues, while being sensitive to hosting costs.',
    ],
    requiredSkills: [
      'A bachelor\'s degree in Computer Science, related technical field, or commensurate experience.',
      '5+ years experience in production web application development.',
      '3+ years of experience working with Kubernetes.',
      '3+ years experience working with Ansible, Chef or Puppet.',
      'Writing bash scripts, for fast, reliable deployment.',
      'Continuous Integration and Deployment Automation.',
      'Experience with both AWS and GCP.',
      '2+ year experience with Terraform, Docker & Docker-compose.',
      'Lucidcharts or similar architecture diagram software.',
      'Cringes at the thought of spinning up infrastructure without configuration, knows IaC best practices.',
    ],
    bonusSkills: [
      'Experience with Azure.',
      'Experience with GitLab, CircleCI, Jenkins.',
      'Experience with PowerShell.',
    ],
  },
  'project-manager': {
    title: 'Project Manager',
    description: 'Our team is in need of a process oriented, quality control, client expectation management, team task tracking guru. You should understand how to build an enterprise grade software application from conception to release, as well as how to translate between a client and an engineer.',
    responsibilities: [
      'Work as part of a collaborative, agile team to deliver applications that will leave our clients begging for more.',
      'Work directly with the client to guarantee their expectations align with what we are building for them.',
      'Track time budgets and team tasks keeping production in-scope and on-time.',
    ],
    requiredSkills: [
      'A comfortable understanding of software architecture and corporate enterprise lingo.',
      '5+ years experience in the software industry.',
      'Asana, GSuite (docs and sheets), GitHub project/issue tracking.',
      'Active listener and note taker over video calls.',
      'Can keep a level head in stressful situations and stay professional in the face of an angry client.',
      'Ability to empathize with clients and end-users',
      'Can answer: why are we building this application?',
      'Experience building in an agile setting with requirements testing and QA delivery.',
    ],
    bonusSkills: [
      'Experience with Jira, Asana, github projects.',
      'Api testing tooling. Postman, Insomnia.',
      'Database connections tools. MySQL workbench, pgadmin.',
      'Git',
      'Comfortable working in the terminal/CLI.',
    ],
  },
}
