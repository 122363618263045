import React from 'react';
import { Link } from 'gatsby';
import Img from 'gatsby-image';

const InnerLink = ({ title, img, url }) => {
  if (img) {
    return (
      <Link to={url} className="item">
        <span className="img">
          <Img fixed={img} />
        </span>
        <span className="text">
          {title}
        </span>
      </Link>
    );
  }
  return (
    <Link className="nav-link" to={url}>{title}</Link>
  );
}

const NavItem = ({
  title, url, img, collapse,
}) => {
  if (collapse) {
    return (
      <li className="nav-item" data-toggle="collapse" data-target="#navbarSupportedContent">
        <InnerLink title={title} url={url} img={img} />
      </li>
    );
  }
  return (
    <li className="nav-item">
      <InnerLink title={title} url={url} img={img} />
    </li>
  )  
};

export default NavItem;
