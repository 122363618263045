import React from 'react';
import { Link } from 'gatsby';
import pages from '../../data/pages';

const PrototypeFooter = ({ rclass }) => (
  <footer className={`payment_footer_area ${rclass}`}>
    <div className="footer_top_six">
      <div className="container">
        <div className="row">
          <div className="col-lg-4 col-sm-6">
            <div className="f_widget company_widget">
              <h3 className="f-title f_600 w_color f_size_24 mb_40">
                OPNA
              </h3>
              <p>© Opna 2020 All rights reserved.</p>
              <ul className="list-unstyled f_list">
                {pages.policy.map((doc, i) => (
                  <li key={i}>
                    <Link to={doc.url}>
                      {doc.title}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="col-lg-3 col-sm-6">
            <div className="f_widget about-widget">
              <h3 className="f-title f_600 w_color f_size_18 mb_40">About Us</h3>
              <ul className="list-unstyled f_list">
                {
                  pages.about.map((links, index) => (
                    <li key={index}>
                      <Link to={links.url}>
                        {links.title}
                      </Link>
                    </li>
                  ))
                }
              </ul>
            </div>
          </div>
          <div className="col-lg-3 col-sm-6">
            <div className="f_widget about-widget">
              <h3 className="f-title f_600 w_color f_size_18 mb_40">Our Work</h3>
              <ul className="list-unstyled f_list">
                {
                  pages.services.map((links, index) => (
                    <li key={index}>
                      <Link to={links.url}>
                        {links.title}
                      </Link>
                    </li>
                  ))
                }
              </ul>
            </div>
          </div>
          <div className="col-lg-2 col-sm-6">
            <div className="f_widget social-widget">
              <h3 className="f-title f_600 w_color f_size_18 mb_40">Follow Us</h3>
              <div className="f_social_icon">
                {
                  pages.socialIcon.map((item, index) =>{
                    return(
                      <a
                        href={item.url}
                        key={index}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <i className={item.icon}></i>
                      </a>
                    )
                  })
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
);
export default PrototypeFooter;
