export default {
  '/': {
    title: 'Home',
    description: 'Opna Software Consulting and Develoment',
    bannerImage: null,
    noBreadcrumb: true,
  },
  '/about/': {
    title: 'Home',
    description: 'Opna Software Consulting and Develoment',
    bannerImage: null,
  },
  '/about/team/': {
    title: 'Our Team',
    description: 'The OPNA team is made up of elite software engineers coming from the Bay Area’s leading innovators.',
    bannerImage: 'Img-05.jpg',
  },
  '/about/process/': {
    title: 'Our Process',
    description: 'OPNA adheres to proven software development methodologies, road tested by some of the Bay Area\'s top innovators.',
    bannerImage: null,
  },
  '/about/faq/': {
    title: 'Frequently Asked Questions',
    description: 'Answers to common questions on our methodology and pricing model for custom development, staff augmentation and technology auditing services.',
    bannerImage: null,
  },
  '/about/careers/': {
    title: 'Open Positions at OPNA',
    description: 'Join our quickly growing team.',
  },
  '/services/software/': {
    title: 'Application Development',
    description: 'Our top coders are just an automated intake form away.',
    bannerImage: null,
  },
  '/services/dev-ops/': {
    title: 'Dev Ops',
    description: 'Modern Apps Need Modern Infrastructure.',
    bannerImage: null,
  },
  '/services/staff-augmentation/': {
    title: 'Staff Augmentation',
    description: 'Get the help you need, when you need it most.',
    bannerImage: null,
  },
  '/services/research-and-development/': {
    title: 'Research & Development',
    description: 'Greenfield Proof of Concept applications are our specialty.',
    bannerImage: null,
  },
  '/services/process-automation/': {
    title: 'Process Automation',
    description: 'Any task that happens more that once can be automated.',
  },
  '/portfolio/': {
    title: 'Portfolio',
    description: 'Check out some of the projects we\'ve worked on.',
    bannerImage: null,
  },
  '/blog/': {
    title: 'Our Blog',
    description: 'Thought leadership from our brightest minds.',
  },
  '/contact/': {
    title: 'Contact Us',
    description: 'Send us a note, we\'ll get back to you ASAP.',
  },
  '/services/': {
    title: 'Services',
    description: 'A few of our most popular services.',
  },
  '/404/': {
    title: 'Page not found',
    description: 'Please check your url or reach out to contact@opna.dev',
  },
  '/404.html': {
    title: 'Page not found',
    description: 'Please check your url or reach out to contact@opna.dev',
  },
  '/policy/online-privacy-policy/': {
    title: 'Privacy Policy',
  },
  '/policy/end-user-license-agreement/': {
    title: 'End User License Agreement',
  },
  '/policy/terms-and-conditions/': {
    title: 'Terms and Conditions',
  },
  '/thank-you/': {
    title: 'Thanks for reaching out!',
    description: 'We\'ll get back to you as soon as we are able.', 
  },
}
