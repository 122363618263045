import React, { useEffect, useState } from 'react';
import { Link } from 'gatsby';
import Sticky from 'react-stickynode';
import $ from 'jquery/';
// import Img from 'gatsby-image'
import pages from '../data/pages';
import urls from '../data/urls';
import NavItem from './Navigation/NavItem';
import NavLink from './Navigation/NavLink';

const CustomNavbar = ({
  mClass, nClass, cClass, slogo, hbtnClass, portfolio,
}) => {
  const [collapse, setCollapse] = useState(false);

  useEffect(() => {
    require('malihu-custom-scrollbar-plugin/');
    require('malihu-custom-scrollbar-plugin/jquery.mCustomScrollbar.css');
    require('jquery-mousewheel');

    setCollapse(window.innerWidth < 992);
    window.addEventListener('resize', () => {
      const shouldCollapse = window.innerWidth < 992;
      if (shouldCollapse !== collapse) setCollapse(shouldCollapse);
    });

    $('.mega_menu_two .scroll').mCustomScrollbar({
      mouseWheelPixels: 50,
      scrollInertia: 0,
    });
  });

  return (
    <Sticky top={0} innerZ={9999} activeClass="navbar_fixed">
      <header className="header_area">
        <nav className={`navbar navbar-expand-lg menu_one ${mClass}`}>
          <div className={`container ${cClass}`}>
            <Link className={`navbar-brand ${slogo}`} to={urls.home}>
              <img src={require('../img/logo-opna.png')} alt="" />
              <img src={require('../img/logo-opna-color.png')} alt="logo" />
            </Link>
            <button
              className="navbar-toggler collapsed"
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="menu_toggle">
                <span className="hamburger">
                  <span />
                  <span />
                  <span />
                </span>
                <span className="hamburger-cross">
                  <span />
                  <span />
                </span>
              </span>
            </button>

            <div className="collapse navbar-collapse" id="navbarSupportedContent">
              <ul className={`navbar-nav menu ml-auto ${nClass}`}>
                <NavLink url={urls.home} title="Home" collapse={collapse} />
                <li className="dropdown submenu nav-item">
                  <div to="./" title="About" className="dropdown-toggle nav-link ml-3 ml-lg-0 ml-xl-0" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">About</div>
                  <ul role="menu" className="dropdown-menu">
                    {pages.about.map((page, i) => (
                      <NavItem title={page.title} url={page.url} collapse={collapse} key={i}/>
                    ))}
                  </ul>
                </li>
                <li className="dropdown submenu nav-item">
                  <div to="./" title="Services" className="dropdown-toggle nav-link ml-3 ml-lg-0 ml-xl-0" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">Services</div>
                  <ul role="menu" className=" dropdown-menu">
                    {pages.services.map((page, i) => (
                      <NavItem title={page.title} url={page.url} collapse={collapse} key={i}/>
                    ))}
                  </ul>
                </li>
                <NavLink url={urls.portfolio} title="Portfolio" collapse={collapse} />
              </ul>
              {(() => {
                if (collapse) {
                  return (
                    <Link 
                      className={`btn_get btn_hover ${hbtnClass}`}
                      to={urls.contactUs}
                      data-toggle="collapse"
                      data-target="#navbarSupportedContent"
                    >
                      Contact
                    </Link>
                  );
                }
                return (
                  <Link 
                    className={`btn_get btn_hover ${hbtnClass}`}
                    to={urls.contactUs}
                  >
                    Contact
                  </Link>
                );
              })()}
              
            </div>
          </div>
        </nav>
      </header>
    </Sticky>
  );
}

export default CustomNavbar;
